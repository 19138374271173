import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

export async function authGuard() {
  const authService = inject(AuthService);
  const navCtrl = inject(NavController);

  const account = authService.currentUser;
  if (!account) await authService.loadUser();

  if (authService.currentUser) return true;
  await navCtrl.navigateRoot('/login');
  return false;
}
